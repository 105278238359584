import { template as template_f1a91c66e38f4d468e7ea09880364e3f } from "@ember/template-compiler";
const FKLabel = template_f1a91c66e38f4d468e7ea09880364e3f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
