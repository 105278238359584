import { template as template_2427680199da499bb17b6fb6be80153e } from "@ember/template-compiler";
const FKText = template_2427680199da499bb17b6fb6be80153e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
